.game {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.game-board {
  width: 300px;
  margin-bottom: 20px; /* Add space between the game and the buttons */
}

.status {
  margin-bottom: 10px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.square {
  float: left;
  width: 100px;
  height: 100px;
  font-size: 24px;
  text-align: center;
  line-height: 100px;
  border: 1px solid #999;
}

.scoreboard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap if needed */
  margin-top: 80px;
  margin-bottom: 25px;
}

.scoreboard h3 {
  margin: 0;
  margin-right: 30px;
}

.scoreboard button {
  margin-top: 10px; /* Adjust margin as needed */
  margin-right: 10px; /* Add space between the buttons */
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add space between the buttons and the game */
}
