.container {
  position: relative; /* Set container as the relative positioning context */
  padding: 0 40px; /* Add more padding to the left and right sides of the container */
  box-sizing: border-box; /* Ensure padding is included in width calculations */
}

.left-column, .right-column {
  padding: 15px;
  box-sizing: border-box; /* Include padding in width calculation */
  font-size: 22px; /* Increase the font size */
  position: absolute; /* Position columns absolutely */
}

.left-column {
  left: 0; /* Position left column at the left edge of the container */
  width: calc(33.33% - 30px); /* Set the width to 33.33% and adjust for padding */
  background-color: #ffffff; /* Example background color */
}

.right-column {
  left: 50%; /* Center the column horizontally */
  transform: translateX(-50%); /* Adjust the position to center the column */
  width: calc(33.33% - 30px); /* Set the width to 33.33% and adjust for padding */
  background-color: #ffffff; /* Example background color */
}

/* Media query for small and medium screens */
@media (max-width: 992px) {
  .container {
    padding: 0 15px; /* Adjust container padding for smaller screens */
  }

  .left-column, .right-column {
    position: relative; /* Reset position to relative */
    width: 100%; /* Full width for both columns */
    margin: 0; /* Remove any additional margins */
    padding: 15px; /* Ensure consistent padding */
    transform: none; /* Remove transform */
    left: 0; /* Reset left positioning */
  }
}

.left-column h6 {
  margin-bottom: 5px; /* Adjust the margin as needed */
}
