.card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  

  @media screen and (max-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  

  body {
    padding-top: 20px;
  }
  
 
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column; /* Ensure card content stacks vertically */
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img {
    flex: 1; /* Expand to fill remaining space */
    object-fit: cover; /* Maintain aspect ratio */
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-top: 0;
    color: #333;
  }
  
  .card-description {
    color: #555;
  }
  
  .card-info {
    margin: 5px 0;
  }
  
  .card-link {
    margin-top: auto; /* Push link to bottom */
    display: inline-block;
    padding: 10px 20px;
    background-color: #242526;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .card-link:hover {
    background-color: #0056b3;
  }