:root {
    --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-size: 60px;
    --border: 1px solid #474a4d;
    --border-radius: 8px;
    --speed: 500ms; 
}
  
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
  
a {
    color: var(--text-color);
    text-decoration: none;;
}

.navbar {
    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
}

.navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}
  
.icon-button:hover {
    filter: brightness(1.2);
}
  
.icon-button svg { 
    fill: var(--text-color);
    width: 20px;
    height: 20px;
}

.dropdown {
    position: absolute;
    top: var(--nav-size);
    width: 300px;
    transform: translateX(-45%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    height: 0; /* Set initial height to zero */
}
  
.menu {
    width: 100%;
}
  
.menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
}
  
.menu-item .icon-button {
    margin-right: 0.5rem;
}
  
  
.menu-item .icon-button:hover {
    filter: none;
}
  
.menu-item:hover {
    background-color: #525357;
}
  
.icon-right {
    margin-left: auto;
}
  

.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}
  
  
.menu-secondary-enter {
    transform: translateX(110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-secondary-exit {
    transition: none;
}
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
}

.nav-item:hover .dropdown {
    height: auto;
}