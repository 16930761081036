.rps-box {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .scoreboard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 25px;
  }
  
  
  .scoreboard h3 {
    margin: 0;
    margin-right: 30px;
  }
  